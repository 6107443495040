import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>Connor Maze</h1>
        <div className="links">
          <a href="https://linkedin.com/in/realconnormaze" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="mailto:reply@connormaze.com">Contact</a>
        </div>
      </div>
    </div>
  );
}

export default App; 